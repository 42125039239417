import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProfileImg from './img/Profile.jpg';
import StageImg from './img/Stage.jpg';
import WesterhamImg from './img/Westerham.jpg';
import MBEImg from './img/MBE.jpg';
import PublicSpeakingImg from './img/PublicSpeaking.jpg';
import Testimonials from './Testimonials';
import Footer from './Footer';

function Body() {
  return (
    <>
      <Container>
        <br />
        <Row>
          <Col>
            <Row>
              <Container>
                <Row>
                  <Col md={4}>
                    <Image alt="Picture of Malcolm's face" src={ProfileImg} fluid={true} />
                  </Col>
                  <Col>
                  </Col>
                </Row>
              </Container>
            </Row>
            <Row>
              <h2 className="Heading Into">So Far So Good!</h2>
              <p>
                After 24 years of experience in the Army as a musician playing cello on state occasions and banquets at Kensington and Buckingham Palace – amusing stories including the occasion when we played the wrong National Anthem !
              </p>
            </Row>
            <br></br>
          </Col>
          <Col md={5}>
            <Image alt="Malcolm getting his MBE" src={MBEImg} fluid={true} />
          </Col>
        </Row>
        <br />
        <hr />
        <br />
        <div>
          <h4 className="Center">
            Further information <a className="Email" href="mailto: malcolmw51@btinternet.com">malcolmw51@btinternet.com</a> or call <a className="Phone" href="tel: 07800809618">07800 809 618</a>
          </h4>
        </div>
        <br />
        <hr />
        <br />
        <Row>
          <Col md={10}>
            <h2 className="Heading">So Far So Good! - Outline</h2>
            <p>
              Malcolm worked for 16 years at Canine Partners (a charity who train assistance dogs for people with disabilities) where he was National Speaker giving presentations countrywide, worked with Sir Terry Wogan, and also  explained how a dog can be trained to use a washing machine!
            </p>
            <p>
              As a medical assistant in the first Gulf war – nothing gruesome – again light hearted stories of things that could have gone better.
            </p>
            <p>
              The talk finishes with the “behind the scenes” experience of receiving the MBE at Buckingham Palace including when I was mistaken as a member of the Royal Household.
            </p>
            <p>
              Between 2018 and 2023, Malcolm dedicated his time as a volunteer for the charity 'Literacy in a Box', successfully raising over &#163;20,000.
            </p>
            <p>
              The presentation is kept fast moving, informative and entertaining.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Image alt="Malcolm giving a large presentation" src={PublicSpeakingImg} fluid={true} />
            <p className="Annotation">Guest Speaker, Inner wheel National Conference, Theatre Royal. Nottingham</p>
          </Col>
          <Col md={4}>
            <Image alt="Malcolm giving a presentation on a stage" src={StageImg} fluid={true} />
            <p className="Annotation">Keynote Speaker, Rotary National Conference, Bournemouth International Centre</p>
          </Col>
          <Col md={4}>
            <Image alt="Malcolm giving a smaller presentation" src={WesterhamImg} fluid={true} />
            <p className="Annotation">Westerham U3A, Kent</p>
          </Col>
        </Row>
        <br />
        <hr />
        <h2 className="Heading Center">Testimonials</h2>
        <Testimonials />
        <hr />
        <br />
      </Container>
      <Footer />
    </>
  );
}

export default Body;
