function Footer() {
  return (
    <div className="bg-dark">
        <div className="Center Padding">
          Malcolm Wells MBE
        </div>
    </div>
  );
}

export default Footer;
