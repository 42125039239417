import Carousel from 'react-bootstrap/Carousel'

function Testimonials() {
    return (
        <Carousel interval={5000}>
            <Carousel.Item>
                <div className="Testimonial"></div>
                <Carousel.Caption>
                    <p>We've had such a great response from your presentation yesterday, everyone thoroughly enjoyed it. I'm sure you're going to be very busy in the future, so no time to think about retiring yet!</p>
                    <h5 className="Heading">Hove Women's Institute</h5>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="Testimonial"></div>
                <Carousel.Caption>
                    <p>Thank you for your most inspiring talk tonight! Wow your energy and enthusiasm shines through every word.</p>
                    <h5 className="Heading">Wessex Ladies Networking Group</h5>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="Testimonial"></div>
                <Carousel.Caption>
                    <p>An excellent talk, much enjoyed by our U3A. Rated excellent on our Speakers List.</p>
                    <h5 className="Heading">Hatfield U3A, Hertfordshire</h5>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="Testimonial"></div>
                <Carousel.Caption>
                    <p>Informative, , Interesting, Lively, Laughs included! A Serious message with a top quality delivery</p>
                    <h5 className="Heading">Chairman – Croydon U3A</h5>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="Testimonial"></div>
                <Carousel.Caption>
                    <p>Thank you for an absolutely brilliant afternoon. Your talk was great and everyone I spoke to was full of praise!</p>
                    <h5 className="Heading">Bexhill Museum Limited</h5>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="Testimonial"></div>
                <Carousel.Caption>
                    <p>A great Speaker, entertaining and interesting talk. Don’t miss it!</p>
                    <h5 className="Heading">Senlac Rotary Club</h5>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <div className="Testimonial"></div>
                <Carousel.Caption>
                    <p>A highly entertaining and engaging talk delivered without props or notes by an excellent speaker.</p>
                    <h5 className="Heading">Bransgore Probus Club</h5>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default Testimonials;
